import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FindIdPwPopup = _resolveComponent("FindIdPwPopup")!

  return (_openBlock(), _createBlock(_component_FindIdPwPopup, {
    result: _ctx.result,
    onClear: _ctx.clear,
    onFindId: _ctx.findId,
    onResetPw: _ctx.resetPw
  }, null, 8, ["result", "onClear", "onFindId", "onResetPw"]))
}